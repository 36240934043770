@use "sass:map";
/* COLORS */

/* Text */
$text_primary_default: #0F172A;
$text_primary_disabled: #C0CCDA;

$text_secondary_default: #5F6F86;
$text_secondary_disabled: #CED7E5;

$text_tertiary_default: #94A3B8;
$text_tertiary_disabled: #DDE3EB;

$text_quaternary_default: #FFFFFF;
$text_quaternary_disabled: #FFFFFF;

$text_accent_default: #1C6AE0;
$text_accent_disabled: #A8C7F7;

$text_semantic_danger: #D11818;
$text_semantic_success: #138354;
$text_semantic_warning1: #EEC049;
$text_semantic_warning2: #A16000;

/* Background */
$bg_primary_default: #F1F5FB;
$bg_primary_hover: #F1F5F9;

$bg_secondary_default: #FFFFFF;
$bg_secondary_hover: #F1F5F9;

$bg_tertiary_default: #FFFFFF;
$bg_tertiary_hover: #F1F5F9;

$bg_accent_default: #1C6AE0;
$bg_accent_disabled: #A8C7F7;

$bg_semantic_misc: #EAF0F8;
$bg_semantic_info: #DCE9FD;

$bg_semantic_danger1: #E32222;
$bg_semantic_danger2: #FFEBF0;
$bg_semantic_success1: #138354;
$bg_semantic_success2: #E5FFF3;
$bg_semantic_warning1: #EEC049;
$bg_semantic_warning2: #FFFAEC;

$bg_overlay_default: #0F172A80;
$bg_shadow_default: #5771A526;

/* Button */
$button_primary_default: #1C6AE0;
$button_primary_hover: #2063C7;
$button_primary_disabled: #A8C7F7;

$button_secondary_default: #D8E9FE;
$button_secondary_hover: #CDE0F8;
$button_secondary_disabled: #E4F0FF;

$button_ghost_hover: #E5F0FD;

/* Border */
$border_primary_default: #CBD5E1;
$border_primary_disabled: #E2E8F0;

$border_secondary_default: #E2E8F0;

$border_accent_default: #1C6AE0;
$border_accent_hover: #1C6AE0;
$border_accent_disabled: #A8C7F7;

$border_semantic_danger: #E32222;
$border_semantic_success: #138354;
$border_semantic_warning: #EEC049;

/* Icon */
$icon_primary_default: #0F172A;
$icon_primary_disabled: #C0CCDA;

$icon_secondary_default: #94A3B8;
$icon_secondary_disabled: #E2E8F0;

$icon_tertiary_default: #FFFFFF;
$icon_tertiary_disabled: #FFFFFF;

$icon_accent_default: #1C6AE0;
$icon_accent_disabled: #A8C7F7;

$icon_semantic_danger: #E32222;


/* THEME */
$dark-theme: false !default;

@if $dark-theme {
  /* Text */
  $text_primary_default: #F7F8FA;
  $text_primary_disabled: #414F63;

  $text_secondary_default: #8C99B1;
  $text_secondary_disabled: #3D4A5C;

  $text_tertiary_default: #64748B;
  $text_tertiary_disabled: #39475B;

  $text_quaternary_default: #FFFFFF;
  $text_quaternary_disabled: #64748B;

  $text_accent_default: #4C96FF;
  $text_accent_disabled: #2D5696;

  $text_semantic_danger: #FD6D6F;
  $text_semantic_success: #3EBA86;
  $text_semantic_warning1: #FBBA2C;
  $text_semantic_warning2: #FBBA2C;

  /* Background */
  $bg_primary_default: #141D2C;
  $bg_primary_hover: #262E3D;

  $bg_secondary_default: #262E3D;
  $bg_secondary_hover: #333A48;

  $bg_tertiary_default: #333A48;
  $bg_tertiary_hover: #454B59;

  $bg_accent_default: #2973E1;
  $bg_accent_disabled: #1A3968;

  $bg_semantic_misc: #3D4859;
  $bg_semantic_info: #283F62;

  $bg_semantic_danger1: #F56464;
  $bg_semantic_danger2: #3F2F34;
  $bg_semantic_success1: #19C267;
  $bg_semantic_success2: #2B3F37;
  $bg_semantic_warning1: #EEC049;
  $bg_semantic_warning2: #423A32;

  $bg_overlay_default: #0F172ACC;
  $bg_shadow_default: #101216B2;

  /* Button */
  $button_primary_default: #3C82EC;
  $button_primary_hover: #2063C7;
  $button_primary_disabled: #0F2E5E;

  $button_secondary_default: #D8E9FE1A;
  $button_secondary_hover: #CDE0F80D;
  $button_secondary_disabled: #E4F0FF08;

  $button_ghost_hover: #D8E9FE1A;

  /* Border */
  $border_primary_default: #747D87;
  $border_primary_disabled: #475569;

  $border_secondary_default: #474E5B;

  $border_accent_default: #4C96FF;
  $border_accent_hover: #4C96FF;
  $border_accent_disabled: #2D5696;

  $border_semantic_danger: #F56464;
  $border_semantic_success: #19C267;
  $border_semantic_warning: #EEC049;

  /* Icon */
  $icon_primary_default: #F7F8FA;
  $icon_primary_disabled: #414F63;

  $icon_secondary_default: #94A3B8;
  $icon_secondary_disabled: #3D4A5C;

  $icon_tertiary_default: #F1F5F9;
  $icon_tertiary_disabled: #64748B;

  $icon_accent_default: #4C96FF;
  $icon_accent_disabled: #2D5696;

  $icon_semantic_danger: #F56464;

}

$border_radius: (
  radius-xxs: 4px,
  radius-xs: 8px,
  radius-s: 10px,
  radius-sm: 16px,
  radius-xxxl: 100px,
);

